import React from 'react'
import Layout from '../components/layout'
import PageTitle from '../components/page-title'
import Signature from '../components/images/signature'
import Heading from '../components/heading'
import SEO from '../components/seo'

export default function Message() {
  const title = {
    jp: 'ごあいさつ',
    en: 'MESSAGE'
  }

  const heading = '社長ご挨拶'

  const mainMassage = '「最も大事なことは、義理・人情・恩返し」'

  const messages = [
    {
      id: 'contents-01',
      contents: 'これは創業者・小山田政幸がいつも言っていた言葉です。'
    },
    {
      id: 'contents-02',
      contents:
        '海藻の販売というのは、漁師がいて、地元の漁協の人たちがあってこそ、成り立っています。<br />' +
        'お互いが困っていれば助け合う、相手のことを考える、時には利益よりも相手への恩を優先する。<br />' +
        'そんな人間関係のつながりがあるからこそ、海を知る地元の人だけが持つわかめの情報を教えてもらえたり、<br />' +
        '希少な海藻を仕入れることができるのだと思っています。'
    },
    {
      id: 'contents-03',
      contents:
        '利益だけを考えれば、安い海藻を仕入れる方がいいでしょう。<br />' +
        'しかし私たちが優先することは「おいしさ」です。'
    },
    {
      id: 'contents-04',
      contents: '昔、うちの祖母が、「これが一番おいしい」と言って、いつも同じお茶ばかりを飲んでいました。'
    },
    {
      id: 'contents-05',
      contents: 'その言葉が心に残り、「おいしいものは、ずっと選んでもらえるんだ」という信念が生まれた気がします。'
    },
    {
      id: 'contents-06',
      contents: 'そして、この祖母の言葉が、自分がおいしさにこだわり、おいしさを追及する原点になっているのでしょう。'
    },
    {
      id: 'contents-07',
      contents:
        'これからも、創業者の「義理・人情・恩返し」の信条を受け継ぎ、<br />' +
        '一人でも多くの人に、「これが一番おいしい」と言ってもらえる、<br />' +
        'わかめや昆布といった商品を届け続けたいと思っています。'
    },
  ]

  const role = '代表取締役'

  return (
    <Layout>
      <SEO title="メッセージ | 株式会社サンリク" />
      <PageTitle titleJP={title.jp} titleEN={title.en} />
      <div className="max-w-screen-lg mx-auto mt-20 px-6 md:px-0">
        <Heading heading={heading} />
        <h3 className="mt-10 md:mt-20 md:text-center font-bold text-2xl md:text-4xl">{mainMassage}</h3>
        <div className="mt-10 md:mt-20 text-left md:text-center">
          {messages.map(message => (
            <p
              key={message.id}
              id={message.id}
              className="mt-6 md:mt-10 md:text-lg leading-9"
              dangerouslySetInnerHTML={{ __html: message.contents }}
            />
          ))}
        </div>
      </div>
      <div className="flex items-center justify-center mt-20 flex-col md:flex-row">
        <div className="md:pr-4">{role}</div>
        <div className="w-44 md:w-60 md:pl-4 mt-4 md:mt-0">
          <Signature />
        </div>
      </div>
    </Layout>
  )
}
