import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function Signature() {
  return (
    <div className="w-full">
      <StaticImage
        src="../../images/signature.png"
        alt=""
        layout="fullWidth"
        placeholder="blurred"
        formats={[ 'auto', 'webp', 'avif' ]}
      />
    </div>
  )
}